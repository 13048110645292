import GNB from "components/common/GNB";
import MainSection from "sections/Main";
import ServiceSection from "sections/Service";
import CompanySection from "sections/Company";
import ContactSection from "sections/Contact";
import Footer from "sections/Footer";

function HomePage() {
  return (
    <>
      <GNB />
      <MainSection />
      <ServiceSection />
      <CompanySection />
      <ContactSection />
      <Footer />
    </>
  );
}

export default HomePage;
