import styled, { css } from "styled-components";
import media from "styles/media";
import Phone from "assets/img/service-content01-01.png";
import Calendar from "assets/img/service-content01-02.png";
import BG from "assets/img/service-content01-bg.png";
import { ReactComponent as Icon01 } from "assets/icon/service-content01-01.svg";
import { ReactComponent as Icon02 } from "assets/icon/service-content01-02.svg";
import { ReactComponent as Icon03 } from "assets/icon/service-content01-03.svg";
import { ReactComponent as Icon04 } from "assets/icon/service-content01-04.svg";

interface StyledProps {
  align?: string;
  first?: boolean;
}

function Content01() {
  return (
    <Container>
      <InnerWrapper>
        <Content align="left">
          <Title>
            <span>이제 운전이나 짐 없이 간편하게</span>
            <h4>
              버디비와 함께 가벼운
              <br />
              골프여행을 시작하세요
            </h4>
          </Title>
          <img className="asset-01" src={Phone} alt="버디비 소개 이미지" />
          <ContentItem first>
            <div className="icon">
              <Icon01 />
            </div>
            <div className="text-wrapper">
              <h5>쉽고 간편한 예약 시스템</h5>
              <p>
                함께 가는 동반자·도내배송을 한 번에 간편하게 예약할 수 있습니다
              </p>
            </div>
          </ContentItem>
          <ContentItem>
            <div className="icon">
              <Icon02 />
            </div>
            <div className="text-wrapper">
              <h5>제주도 내 배송</h5>
              <p>
                제주도 내 서비스로 제주에서도 내 짐을 편리하게 배송이
                가능합니다.
              </p>
            </div>
          </ContentItem>
        </Content>
        <Content align="right">
          <Title>
            <span>라운딩 일정 따라</span>
            <h4>
              라운딩 스케줄
              <br className="hide-large" /> 기반의
              <br className="hide-small" /> 가장 편리한 배송을 골프비서
              <br className="hide-small" /> 버디비가
              <br className="hide-large" /> 제공합니다
            </h4>
          </Title>
          <img className="asset-02" src={Calendar} alt="버디비 소개 이미지" />
          <ContentItem first>
            <div className="icon">
              <Icon03 />
            </div>
            <div className="text-wrapper">
              <h5>라운딩 스케줄 맞춤 서비스</h5>
              <p>맞춤 배송으로 티오프에 바로 라운딩을 시작할 수 있습니다</p>
            </div>
          </ContentItem>
          <ContentItem>
            <div className="icon">
              <Icon04 />
            </div>
            <div className="text-wrapper">
              <h5>자유로운 단체 서비스 예약</h5>
              <p>
                단체 이용 시 고객별 원하는 서비스만 선택해 예약할 수 있습니다
              </p>
            </div>
          </ContentItem>
        </Content>
      </InnerWrapper>
    </Container>
  );
}

export default Content01;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 380px 348px;
  background: linear-gradient(180deg, #1b1d1f 0%, #18191a 100%);

  &::after,
  &::before {
    content: "";
    position: absolute;
    width: 1634px;
    height: 1021px;
    background-image: url(${BG});
    background-size: 100%;
    opacity: 0.15;
    z-index: 0;
  }

  &::after {
    top: 615px;
    left: 50%;
    transform: rotate(180deg);
  }

  &::before {
    bottom: 74px;
    right: 42.552%;
  }

  ${media.small`
    padding-block: 33.3333vw 28.3333vw;

    &::after,
    &::before {
      width: 166.1111vw;
      height: 104.1666vw;
    }

    &::after {
      top: 173.8888vw;
      left: 6.6666vw;
    }

    &::before {
      bottom: 190.8333vw;
      right: 6.6666vw;
    }
  `};
`;

const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 390px;
  width: 1200px;
  min-width: 1200px;
  z-index: 100;

  & [class^="asset-0"] {
    position: absolute;
    display: block;
  }

  & .asset-01 {
    top: -55px;
    right: -75px;
    width: 542px;
    height: 1008px;
  }

  & .asset-02 {
    position: absolute;
    bottom: 41px;
    left: -74px;
    width: 622px;
    height: 664px;
  }

  ${media.small`
    width: 100%;
    min-width: initial;
    gap: 44.4444vw;

    & [class^="asset-0"] {
      position: static;
      width: 100vw;
      height: auto;
    }

  `};
`;

const Content = styled.div<StyledProps>`
  width: fit-content;
  margin-inline: ${({ align }) => (align === "left" ? " 0 auto" : "auto 0")};

  ${media.small`
    width: 100%;
    margin-inline: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
  `};
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;

  & span {
    color: #fabb00;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 26px;
  }

  & h4 {
    color: #fff;
    font-size: 52px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 180px;
  }

  ${media.small`
    width: 86.6666vw;

    & span {
      font-size: 5.5555vw;
      margin-bottom: 4.4444vw;
    }

    & h4 {
      font-size: 7.7777vw;
      margin-bottom: 8.8888vw;
    }
  `};
`;

const ContentItem = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  gap: 40px;
  margin-bottom: ${({ first }) => first && "67px"};

  & .icon {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    overflow: hidden;
  }

  & .text-wrapper {
    width: 496px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    & h5 {
      color: #ededed;
      font-size: 40px;
      font-weight: 500;
      line-height: normal;
    }

    & p {
      color: #bdbdbd;
      font-size: 26px;
      font-weight: 400;
      line-height: normal;
    }
  }

  ${media.small`
    width: 86.6666vw;
    flex-direction: column;
    align-items: flex-start;
    gap: 8.3333vw;

    & .icon {
      width: 11.1111vw;
      height: 11.1111vw;
      border-radius: 1.8527vw;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    & .text-wrapper {
      width: 100%;
      gap: 2.2222vw;

      & h5 {
        font-size: 7.2222vw;
        font-weight: 700;
      }

      & p {
        font-size: 5vw;
        font-weight: 500;
      }
    }

  `}
`;
