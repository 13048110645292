import {
  css,
  type CSSObject,
  type SimpleInterpolation,
} from "styled-components";

export type BreakPoints = "small" | "large";

export const breakpoints: Record<BreakPoints, number> = {
  small: 600,
  large: 1200,
};

const media = Object.entries(breakpoints).reduce((acc, [key, value]) => {
  return {
    ...acc,
    [key]: (
      first: CSSObject | TemplateStringsArray,
      ...interpolations: SimpleInterpolation[]
    ) => css`
      @media (max-width: ${value}px) {
        ${css(first, ...interpolations)}
      }
    `,
  };
}, {}) as Record<BreakPoints, any>;

export default media;
