import { RouterProvider, createBrowserRouter } from "react-router-dom";
import HomePage from "Home";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <div>Not Found Page</div>,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
