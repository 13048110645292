import styled from "styled-components";
import media from "styles/media";
import { ReactComponent as LogoSVG } from "assets/icon/birdybee-logo.svg";
import { ReactComponent as InstagramSVG } from "assets/icon/instagram.svg";
import { ReactComponent as YoutubeSVG } from "assets/icon/youtube.svg";

const contentList = [
  {
    lable: "회사명",
    value: "주식회사 올빌",
    fullWidth: false,
    marginRightFull: false,
  },
  {
    lable: "대표자명",
    value: "최대한",
    fullWidth: false,
    marginRightFull: true,
  },
  {
    lable: "사업자등록번호",
    value: "634-87-02627",
    fullWidth: false,
    marginRightFull: false,
  },
  {
    lable: "통신판매업신고번호",
    value: "제2023-인천연수구-2269호",
    fullWidth: false,
    marginRightFull: false,
  },
  {
    lable: "주소",
    value: "인천광역시 연수구 인천타워대로 54번길 15-3, 5층",
    fullWidth: true,
    marginRightFull: false,
  },
  {
    lable: "고객센터",
    value: "help@birdybee.kr",
    fullWidth: true,
    marginRightFull: false,
  },
  {
    lable: "대표번호",
    value: "1600-5668",
    fullWidth: true,
    marginRightFull: false,
  },
];

interface StyledProps {
  fullWidth: boolean;
  marginRightFull: boolean;
}

function Footer() {
  return (
    <Container>
      <InnerWrapper>
        <TopSection>
          <Logo>
            <LogoSVG />
          </Logo>
          <SNS>
            <SNS_Button href="https://youtube.com/@birdybee_official">
              <YoutubeSVG />
            </SNS_Button>
            <SNS_Button href="https://www.instagram.com/birdybee_golf">
              <InstagramSVG />
            </SNS_Button>
          </SNS>
        </TopSection>
        <ContentList>
          {contentList.map((item) => (
            <Item
              key={item.lable}
              fullWidth={item.fullWidth}
              marginRightFull={item.marginRightFull}
            >
              <Label>{item.lable}</Label>
              <Value>{item.value}</Value>
            </Item>
          ))}
        </ContentList>
        <Copyright>Copyright 2023 Allbil Corp. All rights reserved.</Copyright>
      </InnerWrapper>
    </Container>
  );
}

export default Footer;

const Container = styled.footer`
  width: 100%;
  background-color: #111213;
`;

const InnerWrapper = styled.div`
  width: 1200px;
  min-width: 1200px;
  margin-inline: auto;
  padding-block: 46px 56px;

  ${media.small`
    width: 86.6666vw;
    min-width: initial;
    padding-block: 8.8888vw 12.2222vw;
  `};
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  ${media.small`
    flex-direction: column;
    gap: 4.7222vw;
    margin-bottom: 3.8888vw;
  `};
`;

const Logo = styled.div`
  width: 114px;
  height: fit-content;

  & svg {
    width: 100%;
  }

  ${media.small`
    width: 26.3888vw;
  `};
`;

const SNS = styled.div`
  display: flex;
  gap: 16px;

  ${media.small`
    gap: 3.3333vw;
  `};
`;

const SNS_Button = styled.a.attrs({
  target: "_blank",
  rel: "noopener",
})`
  display: block;
  width: 60px;
  height: 60px;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.small`
    width: 11.1111vw;
    height: 11.1111vw;
  `};
`;

const ContentList = styled.ul`
  width: 540px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 60px;
  margin-bottom: 43px;

  ${media.small`
    width: auto;
    flex-wrap: nowrap;
    flex-direction: column;
    row-gap: 3.8888vw;
    margin-bottom: 10.2777vw;
  `};
`;

const Item = styled.li<StyledProps>`
  display: flex;
  gap: 16px;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "fit-content")};
  margin-right: ${({ marginRightFull }) =>
    marginRightFull ? "auto" : "initial"};

  ${media.small`
    gap: 8px;
  `};
`;

const Label = styled.p`
  color: #909090;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  white-space: nowrap;
`;

const Value = styled.p`
  color: #d8d8d8;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
  white-space: pre-wrap;
`;

const Copyright = styled.p`
  color: #909090;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.28px;
`;
