import { type BreakPoints, breakpoints } from "styles/media";
type DeviceType = "PC" | "iOS" | "AOS";

const deviceCheck = () => {
  const ua = window.navigator.userAgent;
  const iOS = /iPhone/i.test(ua);
  const AOS = /Android/i.test(ua);
  let deviceType: DeviceType = "PC";
  let deviceSize: BreakPoints = "large";

  if (iOS || AOS) {
    deviceType = iOS ? "iOS" : "AOS";
  }

  if (window.matchMedia(`(max-width: ${breakpoints.small}px)`).matches) {
    deviceSize = "small";
  }

  return { deviceType, deviceSize };
};

export default deviceCheck;
