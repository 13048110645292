import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import media from "styles/media";

const GlobalStyle = createGlobalStyle`
${reset};

:root {
    -webkit-tap-highlight-color: transparent; 
}

html, body {
    width: 100%;
    overflow-x: hidden;
}

* {
    font-family: 'Noto Sans KR', sans-serif !important;
    box-sizing: border-box;
}

.hide-large {
    display: none;
}

.hide-small {
    display: block;
}

${media.small`
  .hide-large {
      display: block;
  }
  
  .hide-small {
      display: none;
  }
`};

`;

export default GlobalStyle;
