import styled from "styled-components";
import media from "styles/media";
import Img01PC from "assets/img/company-content01-01.jpg";
import Img02PC from "assets/img/company-content02-01.jpg";
import Img03PC from "assets/img/company-content03-01.jpg";
import Img04PC from "assets/img/company-content03-02.jpg";
import Img05PC from "assets/img/company-content03-03.jpg";
import Img01MO from "assets/img/company-content01-01-mo.jpg";
import Img02MO from "assets/img/company-content02-01-mo.jpg";
import Img03MO from "assets/img/company-content03-01-mo.jpg";
import Img04MO from "assets/img/company-content03-02-mo.jpg";
import Img05MO from "assets/img/company-content03-03-mo.jpg";

function CompanySection() {
  return (
    <Container id="company">
      <Content01>
        <h3>
          골퍼들의 <br className="hide-large" />
          편리한 이동을 <br className="hide-large" />
          위해
        </h3>
        <img
          className="hide-small"
          src={Img01PC}
          alt="버디비 회사 소개 이미지"
        />
        <img
          className="hide-large"
          src={Img01MO}
          alt="버디비 회사 소개 이미지"
        />
      </Content01>
      <Content02>
        <p className="txt-left">
          버디비가 만드는 골퍼들의 <br className="hide-large" />
          새로운 이동 서비스는
          <br className="hide-small" />
          생각만 <br className="hide-large" />
          해도 가슴 뛰는 목표입니다
        </p>
        <img
          className="hide-small"
          src={Img02PC}
          alt="버디비 회사 소개 이미지"
        />
        <img
          className="hide-large"
          src={Img02MO}
          alt="버디비 회사 소개 이미지"
        />
        <p className="txt-right">
          어렵고, 당연하고 멀게 <br className="hide-large" />
          느껴졌던 이동이 아닌
          <br className="hide-small" /> 쉽고 <br className="hide-large" />
          편리한 이동을 만듭니다
        </p>
      </Content02>
      <Content03>
        <h3>
          버디비가 <br className="hide-large" /> 바꿔나갈
          <br className="hide-small" /> 고품격 골프{" "}
          <br className="hide-large" />
          라운딩 서비스
        </h3>
        <ul className="list">
          <li className="item-01">
            <h5 className="item-title">기술개발과 혁신</h5>
            <p className="item-txt">
              지속 가능한 비즈니스 모델과 기술
              <br />
              개발로 골프 산업을 선도합니다
            </p>
            <img
              className="item-bg hide-small"
              src={Img03PC}
              alt="버디비 회사 소개 이미지"
            />
            <img
              className="item-bg hide-large"
              src={Img03MO}
              alt="버디비 회사 소개 이미지"
            />
          </li>
          <li className="item-02">
            <h5 className="item-title">고객 만족 서비스</h5>
            <p className="item-txt">
              고객 중심의 서비스로 최상의 라운딩
              <br className="hide-small" />
              경험과 안정성을 제공합니다
            </p>
            <img
              className="item-bg hide-small"
              src={Img04PC}
              alt="버디비 회사 소개 이미지"
            />
            <img
              className="item-bg hide-large"
              src={Img04MO}
              alt="버디비 회사 소개 이미지"
            />
          </li>
          <li className="item-03">
            <h5 className="item-title">프리미엄 운송 서비스 </h5>
            <p className="item-txt">
              전문적이고 혁신적인 기술로 클럽 장비 전문 고품질{" "}
              <br className="hide-small" />
              배송서비스의 <br className="hide-large" />
              미래를 만들어갑니다
            </p>
            <img
              className="item-bg hide-small"
              src={Img05PC}
              alt="버디비 회사 소개 이미지"
            />
            <img
              className="item-bg hide-large"
              src={Img05MO}
              alt="버디비 회사 소개 이미지"
            />
          </li>
        </ul>
      </Content03>
    </Container>
  );
}

export default CompanySection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #f2f4f6;
  padding-bottom: 257px;

  ${media.small`
    padding-bottom: 27.7777vw;
  `};
`;

const Content01 = styled.div`
  position: relative;
  width: 100%;
  height: 100dvh;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 1200px;
    min-width: 1200px;
    text-align: center;
    color: #fff;
    font-size: 86px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.26);
  }

  ${media.small`
    & h3 {
      width: 86.6666vw;
      min-width: initial;
      font-size: 13.8888vw;
    }
  `};
`;

const Content02 = styled.div`
  width: 1200px;
  min-width: 1200px;
  margin-block: 241px 358px;

  & [class^="txt-"] {
    color: #252525;
    font-size: 44px;
    font-weight: 700;
    line-height: normal;
  }

  & .txt-left {
    text-align: left;
  }

  & .txt-right {
    text-align: right;
  }

  & img {
    width: 100%;
    height: 758px;
    margin-block: 60px;
  }

  ${media.small`
    width: 86.6666vw;
    min-width: initial;
    margin-block: 34.1666vw 58.0555vw;

    & [class^="txt-"] {
      font-size: 6.66666vw;
    }

    & img {
      height: auto;
      margin-block: 9.7222vw;
    }
  `};
`;

const Content03 = styled.div`
  display: flex;
  flex-direction: column;
  width: 1200px;
  min-width: 1200px;

  & h3 {
    margin-bottom: 108px;
    color: #000;
    text-align: center;
    font-size: 68px;
    font-weight: 700;
    line-height: normal;
  }

  & .list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
  }

  & .list [class^="item-0"] {
    position: relative;
    padding: 48px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.1);
  }

  & .list :is(.item-01, .item-02) {
    width: calc((100% - 48px) / 2);
    height: 612px;
  }

  & .list .item-03 {
    width: 100%;
    height: 512px;
  }

  & .list [class^="item-0"] .item-title {
    position: relative;
    color: #252525;
    font-size: 46px;
    font-weight: 700;
    line-height: normal;
    z-index: 10;
    margin-bottom: 16px;
  }

  & .list [class^="item-0"] .item-txt {
    position: relative;
    color: #2d2d2d;
    font-size: 28px;
    font-weight: 400;
    line-height: normal;
    z-index: 10;
  }

  & .list [class^="item-0"] .item-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  ${media.small`
    width: 86.6666vw;
    min-width: initial;

    & h3 {
      font-size: 9.4444vw;
      margin-bottom: 10vw;
    }

    & .list {
      flex-wrap: nowrap;
      flex-direction: column;
      gap: 6.6666vw;
    }

    & .list [class^="item-0"] {
      width: 100%;
      height: 92.2222vw;
      border-radius: 3.05555vw;
      padding: 7.2222vw;
      box-shadow: 0px 3.891px 10.375px 0px rgba(0, 0, 0, 0.10);
    }

    & .list [class^="item-0"] .item-title {
      font-size: 6.6666vw;
      margin-bottom: 2.5vw;
    }

    & .list [class^="item-0"] .item-txt {
      font-size: 5vw;
    }
  `};
`;
