import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import media from "styles/media";
import deviceCheck from "utils/deviceCheck";
import { debounce } from "lodash";
import { ReactComponent as BirdybeeLogo } from "assets/icon/birdybee-logo.svg";
import { ReactComponent as IOS_Store } from "assets/icon/ios-store.svg";
import { ReactComponent as AOS_Store } from "assets/icon/aos-store.svg";

interface StyledProps {
  pcHide?: boolean;
  active?: boolean;
  open?: boolean;
}

interface ItemList {
  [key: string]: {
    label: string;
    pcHide: boolean;
  };
}

const itemList: ItemList = {
  main: { label: "메인", pcHide: true },
  service: { label: "서비스", pcHide: false },
  company: { label: "회사소개", pcHide: false },
  contact: { label: "문의", pcHide: false },
};

function GNB() {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const setDeviceState = () => {
    const { deviceSize } = deviceCheck();

    setIsMobile(deviceSize === "small");
  };

  const setResizing = debounce(setDeviceState, 500);

  useEffect(() => {
    setDeviceState();
    window.addEventListener("resize", setResizing);

    return () => {
      window.removeEventListener("resize", setResizing);
    };
  }, []);

  const moveToSection = (name: string) => {
    const sections: Record<string, HTMLDivElement> = {
      main: document.querySelector("#main") as HTMLDivElement,
      service: document.querySelector("#service") as HTMLDivElement,
      company: document.querySelector("#company") as HTMLDivElement,
      contact: document.querySelector("#contact") as HTMLDivElement,
    };

    window.scrollTo({ top: sections[name].offsetTop, behavior: "smooth" });

    if (isMobile) {
      setMenuOpen(false);
    }
  };

  const preparingStore = () => {
    alert("준비 중입니다");
  };

  const mobileStoreOpen = () => {
    const { deviceType } = deviceCheck();

    if (deviceType === "iOS") {
      // iOS
    }

    if (deviceType === "AOS") {
      // AOS
    }

    alert(`${deviceType} Store 준비 중입니다`);
  };

  return (
    <Container>
      <InnerWrapper>
        <Logo onClick={() => moveToSection("main")}>
          <BirdybeeLogo />
        </Logo>
        <Menu open={menuOpen}>
          {Object.keys(itemList).map((key) => (
            <Item
              key={key}
              onClick={() => moveToSection(key)}
              pcHide={itemList[key].pcHide}
              // active={position[key].display}
            >
              {itemList[key].label}
            </Item>
          ))}
        </Menu>
        <DownloadWrapper>
          <PCButton onClick={preparingStore}>
            <IOS_Store />
            App Store
          </PCButton>
          <PCButton onClick={preparingStore}>
            <AOS_Store />
            Google Play
          </PCButton>
          <MobileButton onClick={mobileStoreOpen}>앱 다운로드</MobileButton>
        </DownloadWrapper>
        {isMobile && (
          <MobileMenu onClick={() => setMenuOpen(!menuOpen)} open={menuOpen}>
            <span className="line" />
          </MobileMenu>
        )}
      </InnerWrapper>
    </Container>
  );
}

export default GNB;

const Container = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 96px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 900;

  ${media.small`
  height: 60px;
  background-color: transparent;
  backdrop-filter: initial;
  `};
`;

const InnerWrapper = styled.nav`
  display: flex;
  align-items: center;
  width: 1200px;
  min-width: 1200px;

  ${media.small`
    width: 86.6666vw;
    min-width: initial;
  `};
`;

const Logo = styled.div`
  position: relative;
  width: 146px;
  height: 46px;
  margin-right: 117px;
  cursor: pointer;
  user-select: none;
  z-index: 50;

  & svg {
    width: 100%;
    height: 100%;
  }

  ${media.small`
    width: 26.3888vw;
    height: 8.3333vw;
    margin-right: auto;
  `};
`;

const Menu = styled.ul<StyledProps>`
  display: flex;
  gap: 32px;
  margin-right: auto;

  & li {
    transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  }

  ${({ open }) =>
    open
      ? css`
          &::after {
            transform: translate3d(0, 0, 0);
          }

          ${media.small`
            & li {
              transform: translate3d(0, 0, 0);
              opacity: 1;
            }
          `}
        `
      : css`
          &::after {
            transform: translate3d(0, calc(-100% + 60px), 0);
          }

          ${media.small`
          pointer-events: none;
            & li {
              transform: translate3d(0, -60%, 0);
              opacity: 0;
            }
          `}
        `};

  ${media.small`
    position: absolute;
    width: 100vw;
    height: 100dvh;
    top: 0;
    left: 0;
    flex-direction: column;
    gap: initial;
    padding-top: 102px;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(5px);
      transition: transform 300ms ease-in-out;
      z-index: -1;
    }

  `};
`;

const Item = styled.li<StyledProps>`
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  color: ${({ active }) => (active ? "#FABB00" : "#fff")};
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  transition: color 400ms ease-in-out;
  ${({ pcHide }) =>
    pcHide &&
    css`
      display: none;

      ${media.small`
        display: flex;
      `};
    `};

  ${media.small`
    width: 100%;
    justify-content: flex-start;
    padding: 12px 48px;
    font-size: 7.7777vw;
  `};
`;

const MobileMenu = styled.div<StyledProps>`
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 50;

  & .line {
    position: absolute;
    display: block;
    top: 50%;
    transform: translate3d(0, -100%, 0);
    width: 100%;
    height: 1px;
    background-color: #fff;
    transition: background-color 100ms ease-in-out;

    ::before,
    ::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #fff;
      transition: 300ms ease-in-out;
    }

    ${({ open }) =>
      open
        ? css`
            background-color: transparent;

            ::before {
              bottom: 0;
              transform: rotate(135deg);
            }

            ::after {
              bottom: 0;
              transform: rotate(-135deg);
            }
          `
        : css`
            ::before {
              bottom: 6px;
              transform: rotate(0);
            }

            ::after {
              bottom: -6px;
              transform: rotate(0);
            }
          `}
  }
`;

const DownloadWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const PCButton = styled.button`
  width: 180px;
  height: 48px;
  background-color: #2c2c2c;
  border-radius: 4px;
  border: initial;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  gap: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;

  ${media.small`
    display: none;
  `};
`;

const MobileButton = styled.button`
  position: relative;
  display: none;
  width: 78px;
  height: 30px;
  background-color: #2c2c2c;
  border-radius: 15px;
  border: initial;
  overflow: hidden;
  padding: 5px 10px 8px 10px;
  cursor: pointer;
  user-select: none;
  margin-right: 16px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;

  ${media.small`
    display: block;
    z-index: 50;
  `};
`;
