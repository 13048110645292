import styled from "styled-components";
import media from "styles/media";
import { ReactComponent as Next } from "assets/icon/next.svg";
import Img01 from "assets/img/service-content02-01.png";
import Img02 from "assets/img/service-content02-02.png";
import Img03 from "assets/img/service-content02-03.png";

interface StyledProps {
  bgColor: string;
  color: string;
}

function Content02() {
  return (
    <Container>
      <InnerWrapper>
        <Title>
          쉽고 간편하게
          <br />
          바로 시작하는 라운딩
        </Title>
        <Content>
          <Item bgColor="#71767B" color="#D8D8D8">
            <h4>라운딩 3일 전까지 신청</h4>
            <p>영업일 기준 라운딩 3일 전까지 예약합니다</p>
            <img src={Img01} alt="버디비 소개 이미지" />
          </Item>
          <Next className="arrow-icon" opacity={0.5} />
          <Item bgColor="#3C4248" color="#CCCCCC">
            <h4>배송 정보 입력 후 결제</h4>
            <p>픽업정보·동반자 정보와 라운딩 일정을 입력한 후 결제합니다</p>
            <img src={Img02} alt="버디비 소개 이미지" />
          </Item>
          <Next className="arrow-icon" opacity={0.8} />
          <Item bgColor="#18191A" color="#EBEBEB">
            <h4>골프백 픽업 후 배송시작</h4>
            <p>고객이 요청한 위치에서 골프백을 픽업하여 운송을 시작합니다</p>
            <img src={Img03} alt="버디비 소개 이미지" />
          </Item>
        </Content>
      </InnerWrapper>
    </Container>
  );
}

export default Content02;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-block: 153px 188px;
  background: #f2f4f6;

  ${media.small`
    padding-block: 33.3333vw 27.7777vw;
  `};
`;

const InnerWrapper = styled.div`
  width: 1200px;
  min-width: 1200px;

  ${media.small`
    width: 86.6666vw;
    min-width: initial;
  `};
`;

const Title = styled.h3`
  text-align: center;
  color: #000;
  font-size: 66px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 59px;

  ${media.small`
    font-size: 9.4444vw;
    margin-bottom: 17.7777vw;
  `};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 1px;

  ${media.small`
    flex-direction: column;
    gap: 2px;

    & .arrow-icon {
      width: 6.6666vw;
      height: 6.6666vw;
      transform: rotate(90deg);
    }
  `};
`;

const Item = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  width: 372px;
  height: 558px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 20px;
  overflow: hidden;
  padding: 42px 42px 20px 42px;

  & h4 {
    color: #ebebeb;
    font-size: 28px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 15px;
  }

  & p {
    color: ${({ color }) => color};
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: auto;
  }

  & img {
    width: 100%;
    height: 300px;
  }

  ${media.small`
    width: 100%;
    height: 130vw;
    border-radius: 4.4444vw;
    padding: 9.4444vw 9.4444vw 5.5555vw 9.4444vw;

    & h4 {
      font-size: 6.6666vw;
      margin-bottom: 3.3333vw;
    }

    & p {
      font-size: 5vw;
    }

    & img {
      height: 70.5555vw;
    }
  `};
`;
