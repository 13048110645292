import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import media from "styles/media";
import ReactPlayer from "react-player";
import useObserver from "hooks/useObserver";
import deviceCheck from "utils/deviceCheck";
import { debounce } from "lodash";
import { ReactComponent as ScrollDownIcon } from "assets/icon/scroll-down.svg";
import Img01PC from "assets/img/main-content01-01.jpg";
import Img01MO from "assets/img/main-content01-01-mo.jpg";
const videoMO = "https://vimeo.com/video/901046744";
const vicdeoPC = "https://vimeo.com/video/898246872";

function MainSection() {
  const videoRef = useRef<HTMLDivElement>(null);
  const visibility = useObserver(videoRef, 0.3);
  const [url, setUrl] = useState<string>("");
  const [downValue, setDownValue] = useState<number>(0);

  const setVideoUrl = () => {
    const { deviceSize } = deviceCheck();
    const width = window.innerWidth;

    if (deviceSize === "small") {
      setUrl(videoMO);
      setDownValue(width * 1.41666);
      return;
    }
    setDownValue(width * 0.5625);
    setUrl(vicdeoPC);
  };

  const setResizing = debounce(setVideoUrl, 500);

  useEffect(() => {
    // init
    setVideoUrl();
    window.addEventListener("resize", setResizing);
    return () => {
      window.removeEventListener("resize", setResizing);
    };
  }, []);

  const scrollDown = () => {
    window.scrollTo({ top: downValue, behavior: "smooth" });
  };

  return (
    <div id="main">
      <InnerWrppaer>
        <VimeoWrapper ref={videoRef}>
          <div className="txt-wrapper">
            <p>짐·운전없는 편리한 이동</p>
            <h3>버디비</h3>
          </div>
          <ReactPlayer
            className="react-player"
            url={url}
            muted
            loop
            playsinline
            playing={visibility}
            width="100%"
            height="100%"
            controls={false}
          />
          <div className="scroll-down" onClick={scrollDown}>
            <ScrollDownIcon />
          </div>
        </VimeoWrapper>
        <Intro>
          <div className="txt-wrapper">
            <h3>골프를 품격있게</h3>
            <p>
              라운딩 스케줄 기반
              <br className="hide-large" /> 골프백·캐리어 배송 서비스로
              <br className="hide-small" /> 골프
              <br className="hide-large" />
              여행의 품격을 높여드립니다.
            </p>
          </div>
          <img className="hide-small" src={Img01PC} alt="버디비 소개 이미지" />
          <img className="hide-large" src={Img01MO} alt="버디비 소개 이미지" />
        </Intro>
      </InnerWrppaer>
    </div>
  );
}

export default MainSection;

const InnerWrppaer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000;
`;

const VimeoWrapper = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;
  padding-bottom: 56.25%;
  height: 0;

  .react-player {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    pointer-events: none;
  }

  .txt-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 1200px;
    min-width: 1200px;
    color: #fff;
    text-align: center;
    line-height: normal;
    font-weight: 700;
    z-index: 100;

    p {
      text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.26);
      font-size: 56px;
      margin-bottom: 8px;
    }

    h3 {
      text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.26);
      font-size: 76px;
    }
  }

  .scroll-down {
    position: absolute;
    left: 50%;
    bottom: 80px;
    transform: translate3d(-50%, 0, 0);
    width: 56px;
    height: 56px;
    cursor: pointer;
    user-select: none;
    z-index: 100;
  }

  ${media.small`
    height: 0;
    padding-bottom: 141.666%;

    .txt-wrapper {
      width: 86.6666vw;
      min-width: initial;

      p {
        font-size: 6.1111vw;
        font-weight: 500;
      }
      
      h3 {
        font-size: 12.7777vw;
      }
    }

    .scroll-down {
      bottom: 40px;
      width: 10.5555vw;
      height: 10.5555vw;
    }
  `};
`;

const Intro = styled.div`
  position: relative;
  width: 100%;
  height: 752px;

  & .txt-wrapper {
    width: 1200px;
    min-width: 1200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    color: #fff;
    text-align: center;
    line-height: normal;

    h3 {
      font-size: 70px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    p {
      font-size: 48px;
      font-weight: 500;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${media.small`
    height: fit-content;

    & .txt-wrapper {
      width: 86.6666vw;
      min-width: initial;

      h3 {
        font-size: 10.5555vw;
        margin-bottom: 4.7222vw;
      }

      p {
        font-size: 5.5555vw;
        line-height: 150%;
      }
    }

  `};
`;
