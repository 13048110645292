import React, { useEffect, useState, useCallback } from "react";

function useObserver(
  ref: React.RefObject<HTMLDivElement>,
  threshold: number = 0
) {
  const [intersect, setIntersect] = useState<boolean>(false);

  const handleIntersect = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      if (entry.isIntersecting && !intersect) {
        setIntersect(true);
      } else {
        setIntersect(false);
      }
    },
    []
  );

  useEffect(() => {
    const { current } = ref!;

    let observer: IntersectionObserver;

    if (current) {
      observer = new IntersectionObserver(handleIntersect, {
        threshold: threshold,
      });
      observer.observe(current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return intersect;
}

export default useObserver;
