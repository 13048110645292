import React, { useRef } from "react";
import styled, { css } from "styled-components";
import media from "styles/media";
import emailjs from "@emailjs/browser";
import BG_PC from "assets/img/contact-outro.jpg";
import BG_MO from "assets/img/contact-outro-mo.jpg";
import { ReactComponent as EmphasisSVG } from "assets/icon/emphasis.svg";

function ContactSection() {
  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    let valid = false;
    formData.forEach((value) => {
      if (!value) {
        valid = true;
      }
    });

    if (valid) return;

    const emailParams = {
      to_name: formData.get("name"),
      to_phone: formData.get("phone"),
      to_email: formData.get("email"),
      message: formData.get("content"),
    };
    e.currentTarget.reset();

    await emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID as string,
        process.env.REACT_APP_TEMPLATE_ID as string,
        emailParams,
        process.env.REACT_APP_PUBLIC_KEY as string
      )
      .then(() => {
        alert("문의 메일이 발송되었습니다.");
      })
      .catch((e) => {
        console.error(e);
        alert("문의 메일을 발송 실패했습니다.");
      });
  };

  return (
    <Container id="contact">
      <Form onSubmit={submitHandler}>
        <Title>
          <span>서비스/제휴</span>
          <h3>버디비에 문의하기</h3>
        </Title>
        <Input name="name" placeholder="고객/단체명" />
        <Input name="phone" placeholder="연락처" />
        <Input name="email" placeholder="이메일" />
        <TextArea name="content" placeholder="문의내용" />
        <Description>
          <EmphasisSVG className="emphasis" />
          <p>
            단체문의를 원하시는 고객님은 문의 사항을 작성해주시면 담당자가
            빠르게 확인 후 답변드립니다.
          </p>
        </Description>
        <SendButton>보내기</SendButton>
      </Form>
      <Outro>
        <TextWrapper>
          <h3>
            품격있는 제주 <br className="hide-large" />
            골프 여행
            <br className="hide-small" /> 지금 바로{" "}
            <br className="hide-large" />
            버디비로 시작하세요
          </h3>
          <p>
            Google Play와 App store에서 <br className="hide-large" />
            다운 받을 수 있습니다
          </p>
        </TextWrapper>
        <img className="hide-small" src={BG_PC} alt="골프장 이미지" />
        <img className="hide-large" src={BG_MO} alt="골프장 이미지" />
      </Outro>
    </Container>
  );
}

export default ContactSection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
`;

const Form = styled.form`
  width: 1200px;
  min-width: 1200px;
  padding-block: 180px;

  ${media.small`
    width: 86.6666vw;
    min-width: initial;
    padding-block: 33.3333vw 27.7777vw;
  `};
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 68px;

  & h3 {
    color: #000;
    font-size: 64px;
    font-weight: 700;
    line-height: normal;
  }

  & span {
    color: #fa8700;
    font-size: 26px;
    font-weight: 700;
    line-height: normal;
  }

  ${media.small`
    gap: 4.4444vw;

    & h3 {
      font-size: 8.8888vw;
    }

    & span {
      font-size: 5.5555vw;
    }
  `};
`;

const inputStyle = css`
  width: 100%;
  display: block;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #efefef;
  background-color: #fcfcfc;
  margin-bottom: 24px;

  color: #000;
  font-size: 22px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.44px;

  &::placeholder {
    color: #c2c2c2;
  }

  ${media.small`
    font-size: 18px;
    letter-spacing: -0.36px;
    margin-bottom: 20px;
  `};
`;

const Input = styled.input`
  padding: 10px 20px 14px;
  ${inputStyle};

  ${media.small`
    padding: 10px 16px 12px;
  `};
`;

const TextArea = styled.textarea`
  padding: 20px;
  height: 340px;
  ${inputStyle};
  resize: none;

  ${media.small`
    height: 250px;
    padding: 10px 16px 12px;
  `};
`;

const Description = styled.div`
  display: flex;
  margin-bottom: 72px;

  & p {
    color: #838383;
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
  }

  & .emphasis {
    margin-top: 4px;
  }

  ${media.small`
    margin-bottom: 40px;

    & p {
      font-size: 16px;
    }

    & .emphasis {
      margin-top: 2px;
      min-width: 20px;
      min-height: 20px;
    }
  `};
`;

const SendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 258px;
  height: 56px;
  margin: 0 auto;
  border: initial;
  background-color: #2c2c2c;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  user-select: none;

  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.4px;

  ${media.small`
    width: 100%;
    height: 40px;

    font-size: 16px;
    letter-spacing: -0.32px;
  `};
`;

const Outro = styled.div`
  position: relative;
  width: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 1200px;
  min-width: 1200px;
  text-align: center;

  & h3 {
    color: #fff;
    font-size: 68px;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 23px;
  }

  & p {
    color: #e5e5ce;
    font-size: 28px;
    font-weight: 400;
    line-height: normal;
  }

  ${media.small`
    width: 86.6666vw;
    min-width: initial;

    & h3 {
      font-size: 8.8888vw;
      line-height: normal;
      margin-bottom: 7.5vw;
    }

    & p {
      font-size: 5vw;
    }
  `};
`;
