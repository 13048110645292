import styled, { css } from "styled-components";
import media from "styles/media";
import Img01PC from "assets/img/service-content03-01.jpg";
import Img02PC from "assets/img/service-content03-02.jpg";
import Img03PC from "assets/img/service-content03-03.jpg";
import Img01MO from "assets/img/service-content03-01-mo.jpg";
import Img02MO from "assets/img/service-content03-02-mo.jpg";
import Img03MO from "assets/img/service-content03-03-mo.jpg";

interface StyledProps {
  align?: string;
  marginBtm?: boolean;
}

function Content03() {
  return (
    <Container>
      <Title>
        <span>짐 없이 휴대폰만 들고 가는 제주 골프</span>
        <h3>골프백 배송서비스</h3>
      </Title>
      <Item align="right" marginBtm>
        <img className="hide-small" src={Img01PC} alt="버디비 소개 이미지" />
        <img className="hide-large" src={Img01MO} alt="버디비 소개 이미지" />
        <div className="txt-box">
          <h4>왕복배송</h4>
          <p>
            지갑만 들고 가볍게
            <br className="hide-large" /> 떠나는 라운딩
          </p>
        </div>
      </Item>
      <Item align="left" marginBtm>
        <img className="hide-small" src={Img02PC} alt="버디비 소개 이미지" />
        <img className="hide-large" src={Img02MO} alt="버디비 소개 이미지" />
        <div className="txt-box">
          <h4>편도배송</h4>
          <p>집 앞에서 1번 홀까지</p>
        </div>
      </Item>
      <Item align="right">
        <img className="hide-small" src={Img03PC} alt="버디비 소개 이미지" />
        <img className="hide-large" src={Img03MO} alt="버디비 소개 이미지" />
        <div className="txt-box">
          <h4>제주도 내</h4>
          <p>
            다음 골프장까지
            <br className="hide-large" /> 짐 없이 편하게
          </p>
        </div>
      </Item>
    </Container>
  );
}

export default Content03;

const Container = styled.div`
  width: 100%;
  background-color: #fff;
  padding-block: 255px 400px;

  ${media.small`
    padding-block: 33.3333vw 34.7222vw;
  `};
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 1200px;
  min-width: 1200px;
  margin-inline: auto;
  margin-bottom: 128px;

  & span {
    color: #fa8700;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
  }

  & h3 {
    color: #000;
    font-size: 74px;
    font-weight: 700;
    line-height: normal;
  }

  ${media.small`
    width: 86.6666vw;
    min-width: initial;
    gap: 4.4444vw;
    margin-bottom: 21.6666vw;

    & span {
      font-size: 5.5555vw;
    }

    & h3 {
      font-size: 11.1111vw;
    }
  `};
`;

const Item = styled.div<StyledProps>`
  position: relative;
  width: 1560px;
  min-width: 1560px;
  ${({ marginBtm }) =>
    marginBtm &&
    css`
      margin-bottom: 330px;

      ${media.small`
        margin-bottom: 6.6666vw;
      `}
    `};

  ${({ align }) =>
    align === "left"
      ? css`
          margin-right: auto;
          text-align: right;
        `
      : css`
          margin-left: auto;
          text-align: left;
        `}

  & img {
    width: 100%;
    height: 656px;
  }

  & .txt-box {
    position: absolute;
    width: 100%;
    height: fit-content;
    left: 0;
    bottom: 0;
    padding: 70px;
    text-shadow: 0px 3px 4px rgba(0, 0, 0, 0.26);
    line-height: normal;

    h4 {
      color: #fff;
      font-size: 60px;
      font-weight: 700;
      margin-bottom: 8px;
    }

    p {
      color: #ededed;
      font-size: 42px;
      font-weight: 500;
    }
  }

  ${media.small`
    width: 100%;
    min-width: initial;
    margin-left: initial;
    margin-right: initial;
    text-align: left;

    .txt-box {
      padding: 8.3333vw;

      h4 {
        font-size: 7.77777vw;
        margin-bottom: 6px;
      }

      p {
        font-size: 5.5555vw;
      }
    }

    & img {
      height: auto;
    }
  `};
`;
