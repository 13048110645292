import Content01 from "components/service/Content01";
import Content02 from "components/service/Content02";
import Content03 from "components/service/Content03";

function ServiceSection() {
  return (
    <div id="service">
      <Content01 />
      <Content02 />
      <Content03 />
    </div>
  );
}

export default ServiceSection;
